import React, { useState } from 'react'
import { DwApp, Modal, Button, Header, useAuth } from '@datadotworld/dw-utilities'
import Editor, { DiffEditor } from "@monaco-editor/react";

import './App.css'
import '@datadotworld/dw-utilities/dist/index.css'

/**
 * This is a function that fetches the current logged-in user, using the endpoint
 * defined in `ddw_api.js`
 */
async function fetchDwUser() {
  const res = await fetch(`/api/ddw/user`)
  if (!res.ok) {
    return null;
  }
  const user = await res.json();
  return user;
}

async function fetchFile(agentid, datasetid, filename) {
  const res = await fetch(`/api/ddw/file_download/${agentid}/${datasetid}/${filename}`)
  if (!res.ok) {
    return null;
  }
  const body = await res.text()
  return body;
}

async function saveFile(agentid, datasetid, filename, value) {
  const res = await fetch(`/api/ddw/uploads/${agentid}/${datasetid}/files/${filename}`, {
    method: 'PUT',
    body: value
  })
  if (!res.ok) {
    return null;
  }
  const body = await res.text()
  return body;
}

export const DW_AUTH = {
  fetchDwUser,
  fetchFile,
  // These endpoints are defined in `ddw_oauth.js`
  loginUrl: '/api/oauth',
  logoutUrl: '/api/oauth/logout'
}

function App() {
  // Keep a copy of the last form submission.
  return (
    // If you want the app to display a "Login" button for you, you need to pass
    // a `fetchDwUser` function that can be called to load the authenticated user for you.
    <DwApp
    dwAuth={DW_AUTH}
  >
    <EditorWrapper />
    </DwApp>
  )
}
class EditorWrapper extends React.Component {
  state = {
    isSaving: false,
    saveSuccess: false,
    errorFetchingFile: false,
    showDiff: false
  }
  componentDidMount() {
    const params = new URLSearchParams(window.location.search)
    try {
      const [agentid, datasetid] = params.get('dataset').split('/')
      const file = params.get('file')
      fetchFile(agentid, datasetid, file).then(contents => {
        this.setState({
          contents,
          originalContents: contents,
          errorFetchingFile: false,
          agentid,
          datasetid,
          file
        })
      })
    } catch(e) {
      this.setState({
        errorFetchingFile: e,
        contents: 'Error fetching file.\n\nPerhaps no query parameters were set, or maybe you aren\'t logged in.\n\nBe sure to have hit "Open in" on a data.world file.'
      })
    }
  }
  render() {
    const { isSaving, saveSuccess, errorFetchingFile, showDiff, contents, originalContents } = this.state
    return <div>
      {!errorFetchingFile &&
        <Button style={{
          position: 'absolute',
          top: '6.5rem',
          right: '8.5rem',
          zIndex: 1
        }} primary disabled={isSaving} onClick={e => {
          if (!showDiff) {
            this.setState({
              showDiff: true
            })
            return
          }
          this.setState({
            isSaving: true,
          })
          e.preventDefault()
          const {agentid, datasetid, file, contents} = this.state
          saveFile(agentid, datasetid, file, contents).then(t => {
            this.setState({
              isSaving: false,
              showDiff: false,
              originalContents: contents,
              saveSuccess: true
            })
          }).catch(e => {
            alert(e.message)
            this.setState({
              isSaving: false,
              saveSuccess: false
            })
          })
        }}>{isSaving ? 'Saving...' : saveSuccess ? 'Saved' : showDiff ? 'Commit changes' : contents !== originalContents ?  'Review changes' : 'No edits yet'}</Button>
    }
      {showDiff
        ? <DiffEditor 
            height="80vh"
            original={this.state.originalContents}
            options={{
              readOnly: true
            }}
            modified={this.state.contents}
          />
        : <Editor 
        height="80vh"
        language='dataworld-sparql'
        onChange={(value) => {
          this.setState({contents: value, saveSuccess: false})
        }}
        beforeMount={(monaco) => {
          console.log('Monaco', monaco)
          monaco.languages.register({ id: 'dataworld-sparql' })
          monaco.languages.setLanguageConfiguration('dataworld-sparql', conf)
          monaco.languages.setMonarchTokensProvider('dataworld-sparql', monarch)
        }}
        value={this.state.contents}
      />   
    }
    </div>
  }
}

export const keywords = `prefix select where limit group graph optional order
filter having from offset abs add all as asc ask avg base bind bnode bound by
ceil clear coalesce concat construct contains copy count create datatype day
default delete desc describe distinct drop exists floor group_concat hours if in
insert into iri isblank isiri isliteral isnumeric isuri lang langmatches lcase
load max md5 min minus minutes month move named not now offset rand reduced
regex replace round sameterm sample seconds separator service sha1 sha256 sha384
sha512 silent str strafter strbefore strdt strends strlang strlen strstarts
struuid substr sum timezone to tz ucase undef union uri using uuid values with
year`.split(/\s/)

export const functions = `abs add all as asc avg bind bnode bound ceil clear
coalesce concat contains copy count create datatype day default delete desc
drop exists floor hours if in insert into iri isblank isiri isliteral isnumeric
isuri lang langmatches lcase load max md5 min minus minutes month move named
not now offset rand regex replace round sameterm sample seconds separator
service sha1 sha256 sha384 sha512 silent str strafter strbefore strdt strends
strlang strlen strstarts struuid substr sum timezone to tz ucase undef uri
using uuid values with year`.split(/\s/)

export const constants = `false true`.split(/\s/)

export const conf = {
  // explicitly treat ':', '?' and '-' as word characters (small modification of the default pattern)
  wordPattern: /(-?\d*\.\d\w*)|([^`~!@#$%^&*()=+[{\]}\\|;'",.<>/\s]+)/g,
  comments: {
    lineComment: '#'
  },
  brackets: [
    ['{', '}'],
    ['[', ']'],
    ['(', ')'],
    ['<', '>']
  ],
  autoClosingPairs: [
    { open: '{', close: '}' },
    { open: '[', close: ']' },
    { open: '(', close: ')' },
    { open: '"', close: '"' },
    { open: "'", close: "'" },
    { open: '<', close: '>' }
  ],
  surroundingPairs: [
    { open: '{', close: '}' },
    { open: '[', close: ']' },
    { open: '(', close: ')' },
    { open: '"', close: '"' },
    { open: "'", close: "'" },
    { open: '<', close: '>' }
  ]
}
export const monarch = {
  // Set defaultToken to invalid to see what you do not tokenize yet
  // defaultToken: 'invalid',
  ignoreCase: true,

  keywords,
  functions,

  operators: [
    '=',
    '>',
    '<',
    '!',
    '~',
    '?',
    ':',
    '==',
    '<=',
    '>=',
    '!=',
    '&&',
    '||',
    '++',
    '--',
    '+',
    '-',
    '*',
    '/',
    '&',
    '|',
    '^',
    '%',
    '<<',
    '>>',
    '>>>',
    '+=',
    '-=',
    '*=',
    '/=',
    '&=',
    '|=',
    '^=',
    '%=',
    '<<=',
    '>>=',
    '>>>=',
    '^^'
  ],

  builtinSchemas: ['xs', 'xsd', 'rdf', 'rdfs', 'owl'],

  // we include these common regular expressions
  symbols: /[=><!~?:&|+\-*/^%]+/,

  // C# style strings
  escapes: /\\(?:[abfnrtv\\"']|x[0-9A-Fa-f]{1,4}|u[0-9A-Fa-f]{4}|U[0-9A-Fa-f]{8})/,

  brackets: [
    ['(', ')', 'bracket.parenthesis'],
    ['{', '}', 'bracket.curly'],
    ['[', ']', 'bracket.square'],
    ['<', '>', 'bracket.angle']
  ],

  // The main tokenizer for our languages
  tokenizer: {
    root: [
      // uris
      { include: '@uris' },
      { include: '@variables' },
      { include: '@stringLanguageSuffix' },
      // curies
      [
        /[_\w]{0,10}:[-_\w]+/,
        {
          cases: {
            '@builtinSchemas': 'keyword',
            '@default': 'curie'
          }
        }
      ],
      // prefix
      [/[_\w]{1,10}:/, 'keyword'],

      // identifiers and keywords
      [
        /[a-z_$][\w$]*/,
        {
          cases: {
            // '@typeKeywords': 'keyword.type',
            '@keywords': 'keyword',
            '@functions': 'keyword.func',
            '@default': 'identifier'
          }
        }
      ],

      // whitespace
      { include: '@whitespace' },

      // delimiters and operators
      [/[{}()[\]<>]/, '@brackets'],
      // [/[<>](?!@symbols)/, '@brackets'],
      [
        /@symbols/,
        {
          cases: {
            '@operators': 'operator',
            '@default': ''
          }
        }
      ],

      // numbers
      [/\d*\.\d+([eE][-+]?\d+)?/, 'number.float'],
      [/0[xX][0-9a-fA-F]+/, 'number.hex'],
      [/\d+/, 'number'],

      // delimiter: after number because of .\d floats
      [/[;,.]/, 'delimiter'],

      // strings
      [/"([^"\\]|\\.)*$/, 'string.invalid'], // non-teminated string
      [/'([^'\\]|\\.)*$/, 'string.invalid'], // non-teminated string
      [/`([^`\\]|\\.)*$/, 'string.invalid'], // non-teminated string
      [/"/, 'string', '@string."'],
      [/'/, 'string', "@string.'"],
      [/`/, 'string', '@string.`']
    ],

    uris: [[/(<)([^\s>]*)(>?)/, 'keyword.uri']],

    variables: [[/[?$][_a-zA-Z0-9][-_a-zA-Z0-9]*/, 'variable.name']],

    string: [
      [/[^\\"'`]+/, 'string'],
      [/@escapes/, 'string.escape'],
      [/\\./, 'string.escape.invalid'],
      [
        /["'`]/,
        {
          cases: {
            '$#==$S2': { token: 'string', next: '@pop' },
            '@default': 'string'
          }
        }
      ]
    ],

    stringLanguageSuffix: [[/(?!")(@)([a-z]+(?:-[a-z0-9]+)*)/, 'keyword']],

    whitespace: [
      [/[ \t\r\n]+/, 'white'],
      [/#.*$/, 'comment']
    ]
  }
}

export default App
